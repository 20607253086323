<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section :class="['section', { 'mb-0': isNoData }]">
        <FormRow>
          <template v-slot:label>カテゴリーを選択</template>
          <template v-slot:labelNote> <Tooltip tooltipMsg="カテゴリーを選択すると写真の並べ替えができます"/></template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-select">
                <select class="form-control form-select-input" v-model="selectedCategory" @change="categorySelect">
                  <option value="">すべて</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </FormRow>
      </section>
      <section class="section ">
        <div class="row">
          <div class="col">
            <Draggable
              class="photoList"
              :list="listItems"
              @change="handleSort"
              tag="ul"
              :animation="300"
              handle=".photoList-handle"
              :disabled="draggableDisabled"
            >
              <transition-group name="fade" tag="li" class="photoList-set">
                <div class="photoList-item" v-for="item in listItems" :key="item.id">
                  <div class="photoList-thumbnail">
                    <img class="photoList-thumbnail-img" :src="item.image.url" />
                  </div>
                  <div class="photoList-content">
                    <p class="photoList-category">
                      <span class="photoList-category-title">カテゴリー</span>{{ item.category.name }}
                    </p>
                    <p class="photoList-comment" v-line-clamp="lines">
                      {{ item.comment }}
                    </p>
                    <ul class="photoList-action">
                      <li class="photoList-action-item">
                        <router-link
                          class="btn btn-sm btn-w100 btn-bd-main photoList-action-btn"
                          :to="{ name: 'ShopGalleryEdit', params: { id: item.id } }"
                          >編集</router-link
                        >
                      </li>
                      <li class="photoList-action-item">
                        <button
                          class="btn btn-sm btn-w100 btn-bd-red photoList-action-btn"
                          type="button"
                          @click="handleDeleted(item.id)"
                        >
                          削除
                        </button>
                      </li>
                    </ul>
                  </div>
                  <i v-if="!draggableDisabled" class="aikon aikon-handle photoList-handle"></i>
                </div>
              </transition-group>
            </Draggable>
            <div v-if="isNoData" class="noData">
              <p>{{ noDataMessage }}</p>
            </div>
            <div class="row justify-content-center">
              <div class="col-6">
                <template v-if="listItems.length > 0">
                  <template v-if="listItems.length > 0">
                    <button
                      v-if="galleryCount - limit * offset > 0"
                      class="btn btn-black"
                      type="button"
                      @click="hasMore"
                    >
                      残り{{ listHide }}件
                    </button>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal @close="closeModal()" v-if="modal">
        <template v-slot:headline>削除</template>
        <template v-slot:body>
          <p class="description text-align-center">この写真を削除しますか？</p>
          <ul class="listGrid justify-content-center">
            <li class="listGrid-item">
              <button class="btn btn-lg btn-white" type="button" @click="closeModal()">キャンセル</button>
            </li>
            <li class="listGrid-item">
              <ActionButton
                class="btn btn-lg btn-red"
                :handle-submit="() => deleteGallery(deleteId)"
                button-text="削除する"
              />
            </li>
          </ul>
        </template>
      </Modal>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: 'ShopGalleryRegister' }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import lineClamp from 'vue-line-clamp';
import Draggable from 'vuedraggable';
import nav from '@/mixins/nav/gallery';
import modal from '@/mixins/plugin/modal';
import { COMMON_MESSAGE } from '@/config/message';
//component
import Tooltip from '@/components/Tooltip.vue';
import FormRow from '@/components/FormRow.vue';
import Modal from '@/components/Modal.vue';

Vue.use(lineClamp, {
  importCss: true,
});

export default {
  name: 'ShopGalleryPhotoList',
  data: function() {
    return {
      pageName: 'ギャラリー',
      lines: 3,
      draggableDisabled: true,
      selectedCategory: '',
      limit: 9,
      offset: 1,
      sortList: [],
      deleteId: null,
      paramsStatePath: 'gallery/listParams',
    };
  },
  components: {
    Draggable,
    Tooltip,
    FormRow,
    Modal,
  },
  mixins: [nav, modal],
  computed: {
    ...mapGetters({
      galleries: 'gallery/galleryList',
      categories: 'gallery/categoryList',
      galleryCount: 'gallery/galleryCount',
      userInfo: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
    listItems: {
      get: function() {
        return this.sortList;
      },
      set: function(newValue) {
        this.sortList = newValue;
      },
    },
    listHide() {
      return this.galleryCount - this.limit * this.offset;
    },
    isNoData() {
      return this.galleries.length === 0;
    },
    noDataMessage() {
      return COMMON_MESSAGE.noData;
    },
  },
  watch: {
    async selectedCategory(value) {
      this.offset = 1;
      this.listItems = [];
      await this.getGalleries(value);
      this.listItems = this.galleries;
    },
  },
  methods: {
    async handleSort(value) {
      this.$message.reset();
      let newIndexList = [];
      this.listItems.forEach((el) => {
        newIndexList.push(el.ord);
      });
      let index;
      if (value.moved.newIndex < value.moved.oldIndex) index = value.moved.newIndex + 1;
      else index = value.moved.newIndex - 1;
      const data = {
        id: value.moved.element.id,
        sortOrder: newIndexList[index],
      };
      const result = await this.$store.dispatch('gallery/sortGalleries', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        categoryId: this.selectedCategory,
        data: data,
      });
      if (result) {
        this.$message.updated('gallery');
      }
      await this.getGalleries(this.selectedCategory);
      this.sortList = this.galleries;
    },
    categorySelect: function() {
      if (this.selectedCategory === '') {
        this.draggableDisabled = true;
      } else {
        this.draggableDisabled = false;
      }
    },
    async hasMore() {
      this.offset++;
      await this.getGalleries(this.selectedCategory, this.limit, this.offset);
      this.sortList = this.sortList.concat(this.galleries);
    },
    async deleteGallery(id) {
      const result = await this.$store.dispatch('gallery/deleteGallery', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        id: id,
      });
      this.closeModal();
      if (result) {
        await this.getGalleries(this.selectedCategory);
        this.sortList = this.galleries;
        this.$message.deleted('gallery');
      }
    },
    getGalleries(categoryId) {
      return this.$store.dispatch('gallery/getGalleries', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        categoryId: categoryId,
        limit: this.limit,
        offset: this.offset,
      });
    },
    getCategories() {
      return this.$store.dispatch('gallery/getCategories', {
        subdomain: this.subdomain,
        shopId: this.shopId,
      });
    },
    handleDeleted(id) {
      this.showModal();
      this.deleteId = id;
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    const initialListParams = JSON.parse(sessionStorage.getItem(this.paramsStatePath));
    if (initialListParams) {
      this.selectedCategory = initialListParams.selectedCategory;
      this.limit = initialListParams.limit;
      this.offset = initialListParams.offset;
    }
    sessionStorage.removeItem(this.paramsStatePath);
    Promise.all([this.getGalleries(this.selectedCategory, this.limit, this.offset), this.getCategories()]).finally(
      () => {
        this.sortList = this.galleries;
        this.$loading.clear(loading);
      }
    );
  },
  beforeDestroy() {
    if (this.$route.name === 'ShopGalleryEdit') {
      const listParams = {
        selectedCategory: this.selectedCategory,
        limit: this.limit,
        offset: this.offset,
      }
      sessionStorage.setItem(this.paramsStatePath, JSON.stringify(listParams));
    } else {
      sessionStorage.removeItem(this.paramsStatePath);
    }
  },
};
</script>
